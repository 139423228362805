<template>
  <div class="container-fluid px-0 py-2">
    <div class="col-12">
      <div class="p-dash-cliente-master">
        <div class="col-12 col-md-12 col-lg-11 mx-auto">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div
                  class="col-6 px-0 py-2 aos-init"
                  data-aos="fade"
                  data-aos-delay="100"
                >
                  <router-link :to="{ name: 'KitUnity' }">
                    <img
                      src="~@/assets/img/botao-voltar.png"
                      class="aos-init"
                      height="35"
                      data-aos="fade"
                      data-aos-delay="300"
                      alt="Qsuíte"
                    />
                  </router-link>
                </div>
                <div
                  class="col-6 px-0 py-2 text-right aos-init"
                  data-aos="fade"
                  data-aos-delay="100"
                >
                  <!-- <img src="~@/assets/img/hub_/hub-logo.svg" class="img-fluid" title="Logo" style="max-height:40px"> -->
                </div>
              </div>
            </div>

            <div class="col-12 text-center" v-if="trigger">
              <h3
                class="font-30 fontm-18 font-b-4 mb-2 pt-2 aos-init"
                data-aos="fade"
                data-aos-delay="200"
                style="color: var(--color)"
              >
                {{ titleStep }}
              </h3>
              <div
                class="w-100 pt-2 aos-init"
                data-aos="fade"
                data-aos-delay="300"
                style="border-top: 1px #e9e9e9 solid"
              >
                <h3
                  class="mb-1 font-15 font-b-5 pt-0 mb-2"
                  style="
                    color: #8d8d8d;
                    line-height: 20px;
                    text-transform: uppercase;
                  "
                >
                  {{ subTitleStep }}
                </h3>
              </div>
            </div>

            <!-- STAND -->
            <div
              class="col-12 col-md-12 col-lg-8 mx-auto text-center px-0"
              v-if="!isStone && trigger"
            >
              <div class="col-12">
                <div class="row">
                  <div
                    class="col-12 col-md-6 col-lg-6 mx-auto p-3 mb-4"
                    v-for="item in trigger.itens"
                    :key="item.id"
                  >
                    <div class="text-right rollover">
                      <img
                        :src="item.item.image2"
                        class="img-fluid on aos-init"
                        data-aos="fade"
                        data-aos-delay="300"
                        alt="Qsuíte"
                        style="max-height: 350px; border-radius: 12px"
                      />

                      <img
                        :src="item.item.image"
                        class="img-fluid off aos-init"
                        data-aos="fade"
                        data-aos-delay="300"
                        alt="Qsuíte"
                        style="max-height: 350px; border-radius: 12px"
                      />

                      <img
                        src="~@/assets/img/touch-screen.png"
                        class="img-fluid ico aos-init"
                        data-aos="fade"
                        data-aos-delay="300"
                        alt="Qsuíte"
                      />

                      <div
                        class="pt-4 aos-init"
                        data-aos="fade"
                        data-aos-delay="400"
                        style="
                          border-bottom: 2px var(--color) solid;
                          margin-bottom: 20px;
                          padding-left: 30px;
                        "
                      >
                        <h2
                          class="mb-1 font-15 font-b-5 pt-0 mb-2"
                          style="color: #8d8d8d"
                        >
                          {{ item.item.name }}
                        </h2>
                      </div>
                      <div
                        class="w-100 aos-init"
                        data-aos="fade"
                        data-aos-delay="500"
                      >
                        <button
                          type="submit"
                          class="btn btn-info"
                          style="background-color: var(--color)"
                          @click="selectItem(item)"
                        >
                          <span style="font-size: 16px">Selecionar</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- STONE -->
            <div class="col-12 mx-auto text-center pt-2 px-0" v-show="isStone">
              <div class="col-12">
                <div class="row">
                  <div
                    class="col-12 col-md-6 col-lg-4 p-0 p-sm-3 mb-4 mx-auto aos-init"
                    data-aos="fade"
                    data-aos-delay="300"
                    style="max-width: 360px"
                    v-for="item in item.stones"
                    :key="item.id"
                  >
                    <div>
                      <div class="mb-3 text-right">
                        <img
                          :src="item.stone.image"
                          class="img-fluid"
                          alt="Qsuíte"
                          style="max-height: 170px; border-radius: 12px"
                        />
                        <div
                          class="pt-4"
                          style="
                            border-bottom: 2px var(--color) solid;
                            padding-left: 30px;
                          "
                        >
                          <h3
                            class="mb-1 font-15 font-b-5 py-0 mb-2"
                            style="color: #8d8d8d"
                          >
                            {{ item.stone.name }}
                          </h3>
                        </div>
                        <div class="pt-2" style="padding-left: 30px">
                          <h3
                            class="mb-1 font-15 font-b-5 pt-0 mb-2"
                            style="color: #8d8d8d; line-height: 20px"
                          >
                            R$ {{ formatMoney(item.stone.price) }}
                          </h3>
                        </div>
                        <div class="w-100 mb-3">
                          <button
                            type="submit"
                            class="btn btn-info"
                            style="background-color: var(--color)"
                            @click="selectStone(item)"
                          >
                            SELECIONAR
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <BaseClientKitConditions />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import KitConfigService from "@/services/resources/KitConfigService";
import TriggerService from "@/services/resources/TriggerService";
import Money from "@/mixins/money";
const serviceTrigger = TriggerService.build();
const service = KitConfigService.build();

export default {
  mixins: [Money],
  props: {
    unity_id: {
      type: Number,
    },
    enterprise_id: {
      type: Number,
    },
    tipology_id: {
      type: Number,
    },
  },
  data() {
    return {
      headers: [
        {
          title: "Selecione o tipo de bancada",
          description:
            "A opção com extensão de bancada deve ser utilizado com cooktop",
        },
        {
          title: "Selecione o tipo de pedra",
          description: "QUANDO ESCOLHEREM COM EXTENSÃO DE BANCADA",
        },
        {
          title: "Selecione o nicho do banhos",
          description: null,
        },
        {
          title: "Selecione o tipo de pedra",
          description:
            "QUANDO ESCOLHEREM O NICHO TODAS AS PEDRAS DO AMBIENTE SERÃO ALTERADAS",
        },
      ],
      stands: [],
      stones: [],
      stones_niches: [],
      niches: [],
      triggers: [],
      item: {
        name: null,
        subtitle: null,
      },
      isStone: false,
      step: 0,
      order: {
        unity_id: null,
        enterprise_id: null,
        tipology_id: null,
        itens: [],
      },
      unity: {
        number: null,
        tipology: {
          name: null,
          description: null,
        },
      },
      titleStep: null,
      subTitleStep: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getProfile;
    },
    trigger() {
      if (this.step == this.triggers.length) return null;
      return this.triggers[this.step];
    },
  },
  methods: {
    selectItem(item) {
      let step = {
        item: item.item,
      };
      this.order.itens.push(step);
      if (item.stones.length > 0) {
        this.isStone = true;
        this.item = item;
      } else {
        this.step++;
        this.item = item;
        if (this.step == this.triggers.length) {
          let order = {
            itens: this.order.itens,
            unity_id: this.order.unity_id,
            enterprise_id: this.order.enterprise_id,
            tipology_id: this.order.tipology_id,
          };
          localStorage.order = JSON.stringify(order);
          this.$router.push({
            name: "KitShop",
            params: order,
          });
        }
      }

      this.titleStep = item.name;
      this.subTitle = item.subtitle;
    },
    selectStone(item) {
      this.order.itens[this.order.itens.length - 1].stone = item.stone;
      this.step++;
      this.isStone = false;
      if (this.step == this.triggers.length) {
        let order = {
          itens: this.order.itens,
          unity_id: this.order.unity_id,
          enterprise_id: this.order.enterprise_id,
          tipology_id: this.order.tipology_id,
        };
        localStorage.order = JSON.stringify(order);
        this.$router.push({
          name: "KitShop",
          params: order,
        });
      }

      console.log(item);
      this.titleStep = this.triggers[this.step].name;
    },
    getStones() {
      if (this.order.itens[0].stand_type == "NO_EXTENSION") {
        this.headers[1].description =
          "QUANDO ESCOLHEREM SEM EXTENSÃO DE BANCADA";
        return this.stones.filter((elem) => {
          return elem.is_stand;
        });
      }

      return this.stones.filter((elem) => {
        return !elem.is_stand;
      });
    },
    selectStand(item) {
      this.order.itens.push(item);
      this.step = 1;
    },
    fetchKitConfig() {
      service
        .search()
        .then((resp) => {
          resp.forEach((element) => {
            if (element.type == "STAND") {
              this.stands.push(element);
            } else if (element.type == "NICHE") {
              this.niches.push(element);
            } else if (element.type == "STONE" && !element.is_niche) {
              this.stones.push(element);
            } else if (element.type == "STONE" && element.is_niche) {
              this.stones_niches.push(element);
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchTrigger() {
      let data = {
        tipology_id: this.order.tipology_id,
      };
      serviceTrigger
        .search(data)
        .then((resp) => {
          this.triggers = resp;
          this.titleStep = resp[0].name;
          this.subTitle = resp[0].subtitle;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:4932490,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    let order = JSON.parse(localStorage.order);
    this.order.unity_id = order.unity_id;
    this.order.enterprise_id = order.enterprise_id;
    this.order.tipology_id = order.tipology_id;

    this.fetchKitConfig();
    this.fetchTrigger();
  },
};
</script>